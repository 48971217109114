import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import PreviewPanelMainNode from './PreviewPanelMainNode';
import {UilRefresh} from '@iconscout/react-unicons';
import {ACTION_TYPES} from '../../data';
import PreviewPanelSubNode from "./PreviewPanelSubNode";
import PreviewPanelUserActionNode from "./PreviewPanelUserActionNode";

import './PreviewPanel.scss';

const ActionOrBubbleMap = {
  'BUTTON_INPUT' : 'SUB_ACTION',
  'EMAIL_INPUT' : 'MAIN_ACTION',
  'PHONE_INPUT' : 'MAIN_ACTION',
  'TEXT_INPUT' :  'MAIN_ACTION',
  'TEXT_BUBBLE' : 'BUBBLE'
};

const PreviewPanel = ({flows}) => {

  const [previewBotFlows, setPreviewBotFlows] = useState({});
  const [previewBotFlowsOrder, setPreviewBotFlowsOrder] = useState([]);
  const [nextNode, setNextNode] = useState({});
  const [loadNextNode, setLoadNextNode] = useState(false);
  const [reloadFlow, setReloadFlow] = useState(false);
  const [mainFlowNumber, setMainFlowNumber] = useState(0);
  const [activeFlowNumber, setActiveFlowNumber] = useState(0);

const previewFlow = useCallback((nextNode=null) =>{

  let currentNode = nextNode? nextNode:flows.find((node) => node.sourceNodeId === '');
  let botFlows = {};
  let botFlowsOrder = [];

  while (currentNode) {
    if (ActionOrBubbleMap[currentNode.type] === ACTION_TYPES.SUB_ACTION) {
      //eslint-disable-next-line
       const subNodes = flows.filter((item)=> item.parentNode === currentNode?.nodeId);
       if(subNodes.length===0){
           break
       }
        botFlows[`${currentNode?.nodeId}/${ACTION_TYPES.SUB_ACTION}/${mainFlowNumber}`]=subNodes;
        botFlowsOrder.push(`${currentNode?.nodeId}/${ACTION_TYPES.SUB_ACTION}/${mainFlowNumber}`);
       break;
    }else{
        //eslint-disable-next-line
        currentNode = flows.find((node) => node.nodeId === currentNode.targetNodeIds[0]);
        if(!currentNode){
            break
        }
        botFlows[`${currentNode?.nodeId}/${ACTION_TYPES.MAIN_ACTION}/${mainFlowNumber}`]=[currentNode];
        botFlowsOrder.push(`${currentNode?.nodeId}/${ACTION_TYPES.MAIN_ACTION}/${mainFlowNumber}`);
        if(ActionOrBubbleMap[currentNode.type] === ACTION_TYPES.MAIN_ACTION){
            break
        }
    }
  }
  const newPreviewBotFlowsOrder=[...previewBotFlowsOrder,...botFlowsOrder];
  const newPreviewBotFlows={...previewBotFlows,...botFlows};

  setPreviewBotFlowsOrder(newPreviewBotFlowsOrder);
  setPreviewBotFlows(newPreviewBotFlows);
  setMainFlowNumber(Number(mainFlowNumber)+1);

  },[
    flows, 
    setPreviewBotFlows,
    previewBotFlowsOrder,
    previewBotFlows,
    setMainFlowNumber,
    mainFlowNumber
  ]);

const refreshPanel = useCallback(()=>{

  setPreviewBotFlows({});
  setPreviewBotFlowsOrder([]);
  setNextNode({});
  setMainFlowNumber(0);
  setActiveFlowNumber(0);
  setReloadFlow(true);

},[
  setPreviewBotFlows,
  setPreviewBotFlowsOrder,
  setNextNode,
  setReloadFlow,
  setMainFlowNumber,
  setActiveFlowNumber
]);

useEffect(()=>{

    previewFlow();
  //eslint-disable-next-line
},[]);

useEffect(()=>{

  if(loadNextNode){
      previewFlow(nextNode);
      setLoadNextNode(false);
    }

  //eslint-disable-next-line
},[loadNextNode,nextNode]);

useEffect(()=>{

    if(reloadFlow){
      previewFlow();
      setReloadFlow(false);
    }
  //eslint-disable-next-line
  },[reloadFlow, setReloadFlow]);

  return (
    <Card 
      className='preview-panel py-1 px-2' 
      style={{ width: '26rem' }}
    >
    <Card.Body>
      <div className='refresh-button d-flex align-items-end'>
          <Button
            variant='outline-primary'
            id="button-addon2"
            onClick={refreshPanel}
            size="sm"
          >
            <UilRefresh size="15"/>
            Refresh
          </Button>
      </div>
      <div className="chatbot-widget">
          <div className="chatbot-messages">

          {previewBotFlowsOrder?.length > 0 && previewBotFlowsOrder.map((objectKey)=> {

              const objectKeySplit =objectKey.split("/");
              if(objectKeySplit?.length===3){

                  switch(objectKeySplit[1]) {
                      case ACTION_TYPES.MAIN_ACTION:
                          return previewBotFlows[objectKey]?.length !== 0 && previewBotFlows[objectKey].map((node,index)=>{
                              return <PreviewPanelMainNode
                                  node={node}
                                  key={`${objectKey}_${index}`}
                                  setPreviewBotFlowsOrder={setPreviewBotFlowsOrder}
                                  previewBotFlowsOrder={previewBotFlowsOrder}
                                  setPreviewBotFlows={setPreviewBotFlows}
                                  previewBotFlows={previewBotFlows}
                                  previewFlow={previewFlow}
                                  loadNextNode={loadNextNode}
                                  setLoadNextNode={setLoadNextNode}
                                  setNextNode={setNextNode}
                                  flowNumber={objectKeySplit[2]}
                                  activeFlowNumber={activeFlowNumber}
                                  setActiveFlowNumber={setActiveFlowNumber}
                              />
                          })
                      case ACTION_TYPES.SUB_ACTION:
                          return previewBotFlows[objectKey]?.length!==0&& <div className='d-flex justify-content-start user-button-group'>
                              {
                                  previewBotFlows[objectKey].map((node,index)=>{
                                      return <PreviewPanelSubNode
                                          node={node}
                                          key={`${objectKey}_${index}`}
                                          setPreviewBotFlowsOrder={setPreviewBotFlowsOrder}
                                          previewBotFlowsOrder={previewBotFlowsOrder}
                                          setPreviewBotFlows={setPreviewBotFlows}
                                          previewBotFlows={previewBotFlows}
                                          previewFlow={previewFlow}
                                          loadNextNode={loadNextNode}
                                          setLoadNextNode={setLoadNextNode}
                                          setNextNode={setNextNode}
                                          flowNumber={objectKeySplit[2]}
                                          activeFlowNumber={activeFlowNumber}
                                          setActiveFlowNumber={setActiveFlowNumber}
                                      />
                                  })
                              }
                          </div>
                      case ACTION_TYPES.USER_ACTION:
                          return previewBotFlows[objectKey]?.length!==0&& <div className='d-flex justify-content-end'>
                              {
                                  previewBotFlows[objectKey].map((node,index)=>{
                                      return <PreviewPanelUserActionNode
                                          userInput={node?.data?.value||""}
                                          key={`${objectKey}_${index}`}
                                      />
                                  })
                              }
                          </div>
                      default:
                          return <div>internal error</div>
                  }
              }else {
                  return <div>internal error</div>
              }
          })}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default PreviewPanel;