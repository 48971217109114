import React, {useCallback} from 'react';
import {Form, Button, Container, Row, Col,InputGroup} from 'react-bootstrap';
import { UilPlus, UilSearch,UilRefresh } from '@iconscout/react-unicons';
import { useNavigate } from "react-router-dom";
import BotListCard from './BotListCard';

import './BotListView.scss';

const BotListView = ({
  bots, 
  getBotFlows, 
  onChangeSearchText, 
  onSearch, 
  searchKey,
  onRefresh
}) => {

  const navigate = useNavigate();
  
  const onNavigateToCreateBotFlow = useCallback(()=> {
    navigate(`/flow-create`);

  },[navigate]);

  return (
    <Container className='mt-3 bot-list-view'>
      <Row>
        <Col xxl={4} xl={4} lg={4} md={4} sm={5} xs={6}>
          <Form className="d-flex">
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search by Flow Name"
                className="me-2"
                aria-label="Search"
                onChange={onChangeSearchText}
                value={searchKey}
              />
                <Button variant="outline-primary" id="button-search" onClick={onSearch}>
                  <UilSearch/>
                </Button>
              </InputGroup>
          </Form>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={3} xs={1}>
          <Button variant="outline-primary" id="button-refresh" onClick={onRefresh}>
            <UilRefresh/>
          </Button>
        </Col>
        <Col  className='create-btn' xxl={2} xl={2} lg={2} md={2} sm={4} xs={5}>
          <Button 
            variant='primary'
            onClick={onNavigateToCreateBotFlow}
            className='white-text-button'
          >
            <div className='my-2 mx-3'>
              <UilPlus size="15"/> Create a Flow Bot
            </div>
          </Button>
        </Col>
      </Row>
      <div className='mt-5'>
        {bots && bots.map((flow) => (
            <BotListCard 
                      botDetails={flow}
                      getBotFlows={getBotFlows}
                      />
        ))}
      </div>
    </Container>
  )
}

export default BotListView