import React, {useCallback, useMemo, useState} from 'react';
import {Button,InputGroup, Form, Image} from 'react-bootstrap';
import {ACTION_TYPES, NODE_TYPES} from '../../data';
import { UilRobot , UilUserCircle} from '@iconscout/react-unicons';
import validator from 'validator';
import { renderLinksFromString } from '../../utils/linkUtils';

import './PreviewPanel.scss';

const PreviewPanelMainNode = ({
    node,
    key,
    setPreviewBotFlowsOrder,
    previewBotFlowsOrder,
    setPreviewBotFlows,
    previewBotFlows,
    setLoadNextNode,
    setNextNode,
    flowNumber,
    activeFlowNumber,
    setActiveFlowNumber
}) => {

  const [isIncorrectEmail, setIsIncorrectEmail] = useState(false);
  const [isIncorrectPhone, setIsIncorrectPhone] = useState(false);
  const [value, setValue] = useState('');

    const onUserInputChange = useCallback((e)=> {

        e.stopPropagation();
        e.preventDefault();
        setValue(e.target.value);

    },[setValue]);


    const onSubmitUserInput = useCallback((e)=> {
        e.stopPropagation();
        e.preventDefault();

        let isValid = true;

        switch(node.type){
            case NODE_TYPES.PHONE_INPUT :
                isValid = validator.isMobilePhone(value);
                isValid ? setIsIncorrectPhone(false) : setIsIncorrectPhone(true);
                break;
            case NODE_TYPES.EMAIL_INPUT :
                isValid = validator.isEmail(value);
                isValid ? setIsIncorrectEmail(false) : setIsIncorrectEmail(true);
                break;
            default:
                isValid = true;
        }

        if (isValid) {
            const newNodeValue={...node};
            newNodeValue.data.value=value;
            const newPreviewBotFlowsOrder=[...previewBotFlowsOrder,`${node?.nodeId}/${ACTION_TYPES.USER_ACTION}/${flowNumber}`];
            const newPreviewBotFlows={...previewBotFlows,[`${node?.nodeId}/${ACTION_TYPES.USER_ACTION}/${flowNumber}`]:[newNodeValue]};
            setPreviewBotFlowsOrder(newPreviewBotFlowsOrder);
            setPreviewBotFlows(newPreviewBotFlows);
            setNextNode(newNodeValue);
            setActiveFlowNumber(Number(flowNumber)+1);
            setLoadNextNode(true);
        }

    },[
        node, 
        value,
        setIsIncorrectEmail, 
        setIsIncorrectPhone,
        previewBotFlowsOrder,
        setPreviewBotFlowsOrder,
        setPreviewBotFlows,
        previewBotFlows,
        setNextNode,
        setLoadNextNode,
        flowNumber,
        setActiveFlowNumber
    ]);

    const displayNode = useMemo(() => {

        switch(node.type){
            case NODE_TYPES.TEXT_BUBBLE : {
                return (
                    <div className='d-flex justify-content-start'>
                        <div className='robot-icon'>
                            <UilRobot/>
                        </div>
                        <div key={key} className='bot-message'>
                            <div>{renderLinksFromString(node.data.value)}</div>    
                        </div>
                    </div>
                );
            }
            case NODE_TYPES.IMAGE_BUBBLE : {
                return (
                    <div className='d-flex justify-content-start'>
                        <div className='robot-icon'>
                            <UilRobot/>
                        </div>
                        <div key={key} className='bot-message'>
                            <Image src={node.data?.imageUrl} rounded fluid/>
                        </div>
                    </div>
                );
            }
            case NODE_TYPES.TEXT_INPUT : {
                return (
                    <>
                        <div className='d-flex justify-content-end'>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder='Text'
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={value}
                                    data-id={node.nodeId}
                                    onChange={onUserInputChange}
                                    type='text'
                                />
                                <Button
                                    variant="outline-primary"
                                    id="button-addon2"
                                    data-id={node.nodeId}
                                    onClick={onSubmitUserInput}
                                >
                                    {node.data.valueSub || 'Enter'}
                                </Button>
                            </InputGroup>
                            <div className='user-icon'>
                                <UilUserCircle/>
                            </div>
                        </div>
                    </>
                );
            }
            case NODE_TYPES.PHONE_INPUT : {
                return (
                    <>
                        <div className='d-flex justify-content-end'>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder='Phone Number'
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={value}
                                    onChange={onUserInputChange}
                                    data-id={node.nodeId}
                                    type='text'
                                />
                                <Button
                                    variant="outline-primary"
                                    id="button-addon2"
                                    onClick={onSubmitUserInput}
                                    data-id={node.nodeId}
                                >
                                    {node.data.valueSub || 'Enter'}
                                </Button>
                            </InputGroup>
                            <div className='user-icon'>
                                <UilUserCircle/>
                            </div>
                        </div>
                        {
                            isIncorrectPhone  &&
                            <div className='d-flex justify-content-start'>
                                <div className='robot-icon'>
                                    <UilRobot/>
                                </div>
                                <>
                                    {
                                        node.data?.valueRetryMessage ?
                                            <div key={key} className='bot-message'>
                                                {`${node.data?.valueRetryMessage}`}
                                            </div>
                                            :
                                            <div key={key} className='bot-message'>
                                                This phone number doesn't seem to be valid. Can you type it again?
                                            </div>

                                    }
                                </>
                            </div>
                        }
                    </>
                );
            }
            case NODE_TYPES.EMAIL_INPUT : {
                return (
                    <>
                        <div className='d-flex justify-content-end'>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder='Email'
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={node.data.userInputValue}
                                    onChange={onUserInputChange}
                                    data-id={node.nodeId}
                                    type='email'
                                />
                                <Button
                                    variant="outline-primary"
                                    id="button-addon2"
                                    onClick={onSubmitUserInput}
                                    data-id={node.nodeId}
                                >
                                    {node.data.valueSub || 'Enter'}
                                </Button>
                            </InputGroup>
                            <div className='user-icon'>
                                <UilUserCircle/>
                            </div>
                        </div>
                        {
                            isIncorrectEmail &&
                            <div className='d-flex justify-content-start'>
                                <div className='robot-icon'>
                                    <UilRobot/>
                                </div>
                                <>
                                    {
                                        node.data?.valueRetryMessage?
                                            <div key={key} className='bot-message'>
                                                {`${node.data?.valueRetryMessage}`}
                                            </div>
                                            :
                                            <div key={key} className='bot-message'>
                                                This email doesn't seem to be valid. Can you type it again?
                                            </div>
                                    }
                                </>

                            </div>
                        }
                    </>
                );
            }
            default : {
                return (
                    <div className='d-flex justify-content-start'>
                        <div className='robot-icon'>
                            <UilRobot/>
                        </div>
                        <div key={key} className='bot-message'>
                            {node?.data?.value}
                        </div>
                    </div>
                );
            }
        }


    }, [
        node, 
        key, 
        isIncorrectEmail, 
        isIncorrectPhone,
        onSubmitUserInput,
        onUserInputChange,
        value
    ]);

    return (
    <>
      {displayNode}
    </>
  )
}

export default PreviewPanelMainNode;