import { fetchPost, fetchGet, fetchDelete, fetchPut, jsonToQueryParam } from "./CommonServiceUtils";
import Constants  from "../Constants";

const createFlowBot = (payload) => {
    return fetchPost(`${Constants.BASE_URL}flowy`, payload);
}

const getFlowBots = (searchKey) => {
    return fetchGet(`${Constants.BASE_URL}flowy?${jsonToQueryParam({searchKey})}`)
}

const getFlowBot = (botId) => {
    return fetchGet(`${Constants.BASE_URL}flowy/${botId}`)
}

const deleteFlowBot = (botId) => {
    return fetchDelete(`${Constants.BASE_URL}flowy/${botId}`)
}

const updateFlowBot = (payload, botId) => {
    return fetchPut(`${Constants.BASE_URL}flowy/${botId}`, payload)
} 

export {createFlowBot, getFlowBots, deleteFlowBot, updateFlowBot, getFlowBot}
