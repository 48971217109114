import React from "react";
import {UilUserCircle} from "@iconscout/react-unicons";

const PreviewPanelUserActionNode=({
    userInput,
    key
})=>{
    return(
        <>
            <div key={key} className='user-message'>
                {userInput}
            </div>
            <div className='user-icon'>
                <UilUserCircle/>
            </div>
        </>
    )
}
export default PreviewPanelUserActionNode;