import React, {useCallback, useEffect, useState} from 'react';
import BaseLayout from '../../Layout/BaseLayout';
import {LoadingComponent} from '../utils'
import {getFlowBots} from '../../Services'
import { toast } from "react-toastify";
import BotCreateView from '../botCreateView/BotCreateView';
import BotListView from '../botListView/BotListView';
import './LandingPage.scss'

const LandingPage = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [botFlows, setBotFlows] = useState([]);
  const [searchKey, setSearchKey] = useState('');

  const getBotFlows = useCallback(async(searchKey)=> {

    try{
      setIsLoading(true);
      const data = await getFlowBots(searchKey);
      setBotFlows(data);
    }catch(e){
      console.error('Failed to load bot flows');
      toast.error(
        'Failed to load bot flows!'
      );
    }finally {
      setIsLoading(false);
    }
  },[setBotFlows,setIsLoading]);

  const onChangeSearchText = useCallback((e)=>{
    setSearchKey(e.target.value);
  },[setSearchKey]);

  const onSearch = useCallback((e)=>{
    e.preventDefault();
    getBotFlows(searchKey);
  },[getBotFlows, searchKey]);

  const onRefresh = useCallback((e)=>{
    e.preventDefault();
    getBotFlows();
    setSearchKey('');
  },[getBotFlows, setSearchKey]);

  useEffect(() => {
    
    getBotFlows();
    // eslint-disable-next-line
  }, []);

  return (
          <>
            {/* {
                isLoading? <LoadingComponent/> : (
                  <> */}
                    {
                      botFlows && botFlows.length > 0 ?
                        <BaseLayout
                          bottom={
                            <div className='flow-list-view-bottom'>
                              { 
                                isLoading? <LoadingComponent/> :
                                  <BotListView 
                                    bots={botFlows} 
                                    getBotFlows={getBotFlows} 
                                    onChangeSearchText={onChangeSearchText} 
                                    onSearch={onSearch}
                                    searchKey={searchKey}
                                    onRefresh={onRefresh}
                                  />
                              }
                            </div>
                        }/>
                      :
                        <BotCreateView text=' First '/>
                    }
                  {/* </>
             
                )
            } */}
          </>
    
        )
  }

export default LandingPage;
