import React, {useCallback, useMemo} from 'react';
import {ACTION_TYPES, NODE_TYPES} from "../../data";
import {UilRobot} from "@iconscout/react-unicons";
import {Button} from "react-bootstrap";

const PreviewPanelSubNode=({
    node,
    key,
    setPreviewBotFlowsOrder,
    previewBotFlowsOrder,
    setPreviewBotFlows,
    previewBotFlows,
    setLoadNextNode,
    setNextNode,
    flowNumber,
    activeFlowNumber,
    setActiveFlowNumber
})=>{
    
    const onClick = useCallback((e)=> {

        e.stopPropagation();
        e.preventDefault();

        const newPreviewBotFlowsOrder=[...previewBotFlowsOrder,`${node?.nodeId}/${ACTION_TYPES.USER_ACTION}/${flowNumber}`];
        const newPreviewBotFlows={...previewBotFlows,[`${node?.nodeId}/${ACTION_TYPES.USER_ACTION}/${flowNumber}`]:[node]};

        setPreviewBotFlowsOrder(newPreviewBotFlowsOrder);
        setPreviewBotFlows(newPreviewBotFlows);
        setNextNode(node);
        setActiveFlowNumber(Number(flowNumber)+1);
        setLoadNextNode(true);

    },[
        node,
        previewBotFlowsOrder,
        setPreviewBotFlowsOrder,
        setPreviewBotFlows,
        previewBotFlows,
        setNextNode,
        setLoadNextNode,
        flowNumber,
        setActiveFlowNumber
    ]);


    const displayNode = useMemo(() => {
        switch(node.type){
            case NODE_TYPES.BUTTON_INPUT_SUB_NODE : {
                return<Button
                    onClick={onClick}
                    variant="outline-primary"
                    className='mb-2 mx-1'
                    key={key}
                    value={node.data.value}
                    data-id={node.nodeId}
                    disabled={Number(flowNumber)!==Number(activeFlowNumber)}
                >
                    {node.data.value}
                </Button>

            }
            default : {
                return (
                    <div className='d-flex justify-content-start'>
                        <div className='robot-icon'>
                            <UilRobot/>
                        </div>
                        <div key={key} className='bot-message'>
                            {node?.data?.value}
                        </div>
                    </div>
                );
            }
        }


    }, [
        node, 
        key,
        onClick,
        activeFlowNumber,
        flowNumber
    ]);

    return(
        <>
            {displayNode}
        </>
    )
}

export default PreviewPanelSubNode