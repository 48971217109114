const NODE_TYPES = {
  TEXT_BUBBLE: 'TEXT_BUBBLE',
  TEXT_INPUT: 'TEXT_INPUT',
  NUMBER_INPUT: 'NUMBER_INPUT',
  PHONE_INPUT: 'PHONE_INPUT',
  WEB_INPUT: 'WEB_INPUT',
  BUTTON_INPUT: 'BUTTON_INPUT',
  BUTTON_INPUT_SUB_NODE : 'BUTTON_INPUT_SUB_NODE',
  DATE_INPUT: 'DATE_INPUT',
  EMAIL_INPUT: 'EMAIL_INPUT',
  IMAGE_BUBBLE: 'IMAGE_BUBBLE'
}

const MAIN_TYPES = {
  ACTION: 'ACTION',
  BUBBLE: 'BUBBLE'
}

const ACTION_TYPES ={
  USER_ACTION: 'USER_ACTION',
  SUB_ACTION: 'SUB_ACTION',
  MAIN_ACTION : 'MAIN_ACTION'
}

export {NODE_TYPES, MAIN_TYPES, ACTION_TYPES};